var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FadeTransition",
    [
      _c("DropZone", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDropZoneVisible,
            expression: "isDropZoneVisible"
          }
        ],
        attrs: {
          "hover-background-color": _vm.graphiteLightColor,
          title: _vm.dropZoneTitle
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [
                _vm.isColumnDragging
                  ? _c("IconRemoveColumn", { attrs: { "fill-color": color } })
                  : _vm.isFilterDragging
                  ? _c("IconRemoveFilter", { attrs: { "fill-color": color } })
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }