var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FadeTransition",
    [
      _c("DropZone", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDropZoneVisible,
            expression: "isDropZoneVisible"
          }
        ],
        attrs: { orientation: _vm.horizontalOrientation, title: "ADD FILTER" },
        on: { drop: _vm.onDropFilter },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [_c("IconAddFilter", { attrs: { "fill-color": color } })]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }