var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "form-subsection",
        {
          "form-subsection--required": _vm.required
        }
      ]
    },
    [
      _vm.title
        ? _c("span", {
            staticClass: "form-subsection__title",
            domProps: { textContent: _vm._s(_vm.title) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }