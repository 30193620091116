var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-page-selector" },
    [
      _c("GridFooterText", { attrs: { title: "Show" } }),
      _vm._v(" "),
      _c("Select", {
        attrs: {
          "data-cy": "grid-visible-rows-select",
          value: _vm.value,
          options: _vm.rowIntervalOptions,
          size: _vm.smallSize
        },
        on: { input: _vm.onValueChange }
      }),
      _vm._v(" "),
      _c("GridFooterText", { attrs: { title: _vm.infoText } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }