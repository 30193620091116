var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormSection",
    { attrs: { title: _vm.title } },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "Tooltip",
        { attrs: { title: _vm.addListTooltip, "max-width": "100%" } },
        [
          _c("Button", {
            attrs: {
              title: _vm.addListTitle,
              size: _vm.smallSize,
              theme: _vm.secondaryTheme,
              disabled: _vm.disabled
            },
            nativeOn: {
              click: function($event) {
                return _vm.onAddListElement.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function(ref) {
                  var color = ref.color
                  return [_c("IconAdd", { attrs: { "fill-color": color } })]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }