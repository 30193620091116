var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-pagination" },
    [
      _c(
        "GridPageSelectorArrowsPanel",
        { attrs: { visible: _vm.isLeftArrowVisible } },
        [
          _c("Fab", {
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.toFirstPage.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowPointerBlock", {
                      attrs: { "fill-color": color }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("Fab", {
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.decrementPage.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowSingle", {
                      attrs: { "fill-color": color, state: _vm.arrow.LEFT }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("GridFooterText", { attrs: { title: "Page" } }),
      _vm._v(" "),
      _c("TextField", {
        staticStyle: { width: "64px" },
        attrs: {
          value: _vm.value,
          size: _vm.smallSize,
          alignment: _vm.centerAlignment,
          input: _vm.inputNumberType
        },
        on: { input: _vm.onValueChange }
      }),
      _vm._v(" "),
      _c("GridFooterText", { attrs: { title: "of" } }),
      _vm._v(" "),
      _c("GridFooterText", { attrs: { title: _vm.maxPage } }),
      _vm._v(" "),
      _c(
        "GridPageSelectorArrowsPanel",
        { attrs: { visible: _vm.isRightArrowVisible } },
        [
          _c("Fab", {
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.incrementPage.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowSingle", {
                      attrs: { "fill-color": color, state: _vm.arrow.RIGHT }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("Fab", {
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.toLastPage.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowPointerBlock", {
                      attrs: { "fill-color": color, state: _vm.arrow.RIGHT }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }